@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-input {
  border: 0;
  border-radius: 1000px;

  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(15px);
  @-moz-document url-prefix() {
    background: theme.$input-bg-color;
  }

  padding: 0 30px;

  font-family: "Mont", sans-serif;
  font-style: normal;
  font-weight: 600;

  height: 35px;
  font-size: 12px;
  line-height: 13px;
  @include grid.lg {
    height: 52px;
    font-size: 14px;
    line-height: 13px;
  }

  /* identical to box height, or 93% */

  color: theme.$input-txt-color;
  outline: none;

  &:focus,
  &:hover {
    outline: none;
    color: #ffffff;
  }
}
