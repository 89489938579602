@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

@use "/src/components/button/buttons.scss";
@use "/src/components/other/titles.scss";
@use "/src/components/kw-video/kw-video.scss";

.kw-s3 {
  display: flex;
  order: 30;
  position: relative;

  @include theme.section-padding;
  @include grid.md {
    padding-top: 101px;
    padding-bottom: 109px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    background-color: #000;
    z-index: -1;

    right: 0;
    height: calc(100% - 208px);
    @include grid.lg {
      right: 50%;
      height: 100%;
    }
  }

  &__txt-block {
    @include grid.lg {
      padding-right: 20px;
    }
  }

  &__h {
    margin-bottom: 20px;
  }

  &__cta {
    margin-top: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20;
    margin-bottom: 30px;

    @include grid.md {
      margin-bottom: 60px;
      display: flex;
      flex-direction: row;

      column-gap: 30px;
      row-gap: 0;
    }
    @include grid.lg {
      display: grid;
      grid-template-columns: 2fr 3fr;
      column-gap: 30px;
      row-gap: 0;
      margin-bottom: 0px;
    }
  }

  &__btn {
    // flex: 1 1 auto;
    width: 100%;
    @include grid.md {
      max-width: 171px;
    }
  }

  &__download {
    display: flex;
    flex-direction: row;

    text-decoration: none;
    font-family: theme.$font-mont;
    font-style: normal;
    .icon {
      background-color: theme.$checkbox-checked-color;
      border-radius: 50%;
      width: 51px;
      height: 51px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 13px;
    }
    .txt-wrap {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      justify-content: center;
      gap: 6px;
    }
  }

  .kw-video {
    position: relative;

    @include grid.md {
      margin-right: 30px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 78px;
        height: 325px;
        right: -30px;
        bottom: -20px;
        z-index: -1;

        background: theme.$acent-header-color;
      }
    }

    @include grid.lg {
      margin-right: 0px;
    }
  }
}
