@use "sass:math";
// Small tablets and large smartphones (landscape view)
$screen-xs-min: 376px;
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 990px;

// Large tablets and desktops
// $screen-xl-min: 1310px;
$screen-xl-min: 1280px;
$screen-xxl-min: 1800px;
$grid__cols: 12;

$sizes: (
  xs: $screen-xs-min,
  sm: $screen-sm-min,
  md: $screen-md-min,
  lg: $screen-lg-min,
  xl: $screen-xl-min,
  xxl: $screen-xxl-min,
);

@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin xs-max {
  @media (max-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin sm-max {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin md-max {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin lg-max {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xl-max {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

@mixin col($size) {
  // flex-basis: #{$size + "%"};
  // width: #{$size + "%"};
  flex-basis: math.div(100, math.div($grid__cols, $size)) * 1%;
  width: math.div(100, math.div($grid__cols, $size)) * 1%;
}

@mixin row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 auto;
  width: 100%;
}

[class^="kw-col"] {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.kw-col {
  @include col($grid__cols);
}

@for $i from 1 through $grid__cols {
  .kw-col-#{$i} {
    @include col($i);
  }
}

@each $name, $size in $sizes {
  .kw-d-none-max-#{$name} {
    @media (max-width: #{$size}) {
      display: none !important;
    }
  }
}

@for $i from 1 through $grid__cols {
  @each $name, $size in $sizes {
    .kw-col-#{$name}-#{$i} {
      @media (min-width: #{$size}) {
        @include col($i);
      }
    }
  }
}

.kw-row {
  @include row;
}
@each $name, $size in $sizes {
  .kw-#{$name}-row {
    @media (min-width: #{$size}) {
      @include row;
    }
  }
}

.kw-container {
  width: 1170px;
  max-width: 100vw;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  @include xl {
    padding: 0px;
  }
}

.f-ai-start {
  align-items: flex-start;
}
.f-ai-end {
  align-items: flex-end;
}
.f-ai-center {
  align-items: center;
}

.f-ac-start {
  align-content: flex-start;
}
.f-ac-end {
  align-content: flex-end;
}
.f-ac-center {
  align-content: center;
}

.f-jc-end {
  justify-content: flex-end;
}
.f-jc-start {
  justify-content: flex-start;
}
.f-jc-center {
  justify-content: center;
}
