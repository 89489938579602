.glav_page,
.header_2 {
  min-width: 320px;
  max-width: 100vw;
  overflow-x: hidden;
}
.app-header {
  & .content {
    @media (max-width: 640px) {
      max-width: 100%;
      width: 100%;
      min-width: 100%;
      padding: 0 15px;
    }
  }
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  opacity: 0;
  z-index: 99;
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  -o-transform: translateY(-200%);
  transform: translateY(-200%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.app-header.sticky {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
@media (max-width: 575px) {
  .app-header {
    display: block;
  }
}
.app-header__top {
  padding-top: 12px;
  padding-bottom: 12px;
}
.app-header__row {
  display: flex;
  justify-content: space-between;
}
.app-header__logo-link {
  margin-right: auto;
}
.app-header__buttons {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.app-header__button {
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;
}

.app-header__button:not(:last-child) {
  margin-right: 10px;
}

.app-header__button-icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-header__button-text {
  font-weight: 800;
  font-size: 14px;
  line-height: 15px;
  color: #3a3a3c;
}
.app-header .menu_btn {
  padding: 8px 12px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  border-radius: 4px;
  text-align: center;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3a3a3c;
  grid-gap: 8px;
  border: none;
  outline: none;
}
.app-header .menu_btn i {
  -webkit-mask-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGxpbmUgeDE9IjMiIHgyPSIyMSIgeTE9IjEyIiB5Mj0iMTIiLz48bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iNiIgeTI9IjYiLz48bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iMTgiIHkyPSIxOCIvPjwvc3ZnPg==);
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-color: white;
}

.app-header__contacts {
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #3a3a3c;
}
.app-header__contacts-row {
  display: flex;
  justify-content: space-between;
}
.app-header__contacts-sign {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
}
.app-header__contacts-sign::after {
  position: absolute;
  top: -0.15rem;
  right: -0.5rem;
  display: block;
  content: " ";
  width: 6px;
  height: 6px;
  background: #00cd4a;
  border-radius: 50%;
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.app-header__phone {
  line-height: 1;
  text-decoration: none;
  color: #fff;
  font-weight: 800;
  font-size: 14px;
  color: #ffffff;
}

.app-header__phone span {
  color: #ff9a00;
}

.online-help {
  height: 60px;
  display: none;
  position: fixed;
  left: 6px;
  right: 6px;
  /* bottom: 120px; */
  bottom: 6px;
  background-color: rgb(49 47 48 / 70%);
  background-color: rgb(112 115 116 / 95%);
  /* background-color: #dedede; */
  border-radius: 8px;
  overflow: hidden;
  z-index: 99;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
  -ms-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.online-help.unactive {
  -webkit-transform: translateY(1000%);
  -moz-transform: translateY(1000%);
  -ms-transform: translateY(1000%);
  -o-transform: translateY(1000%);
  transform: translateY(1000%);
}

.online-help__close {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 22px;
  height: 22px;
  min-height: 22px;
  min-width: 22px;
  max-width: 22px;
  max-height: 22px;
  background-color: #d0d0d0;
  border-radius: 50%;
  border: none;
  outline: none;
}
.online-help__close::before,
.online-help__close::after {
  display: block;
  position: absolute;
  left: 50%;
  right: 50%;
  width: 12px;
  height: 1px;
  content: " ";
  background-color: #312f30;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}
.online-help__close::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  -o-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.online-help__close::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -moz-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  -o-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.online-help__container {
  height: 100%;
  display: flex;
}

.online-help__picture {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.online-help__data {
  position: relative;
  flex: 1 0 auto;
}
.online-help__trigger {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #fff;
  cursor: pointer;
  font-family: Mont, Helvetica, Arial, sans-serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.online-help__trigger.unactive {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}
.online-help__trigger-title {
  display: block;
}
.online-help__trigger-sign {
  position: relative;
  display: block;
  color: rgba(255, 255, 255, 0.6);
}
.online-help__trigger-sign::after {
  position: absolute;
  top: 0rem;
  right: -0.5rem;
  display: block;
  content: " ";
  width: 6px;
  height: 6px;
  background: #00cd4a;
  border-radius: 50%;
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.online-help__buttons {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.online-help__buttons.active {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.online-help__button:not(:last-child) {
  margin-right: 10px;
}

.online-help__button--icon {
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.online-help__button--wa {
  background-color: #00cd4a;
}

.online-help__button--tg {
  background-color: #25a4e5;
}
.online-help__button--text {
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #ffffff;
  text-decoration: underline;
  text-underline-offset: 4px;
}
@media (max-width: 575px) {
  .new22.m_fix-menu {
    display: none;
  }
  .online-help {
    display: block;
  }
}

@media (max-width: 575px) {
  .most_often_rent_2022 .list > div {
    display: flex;
    flex-direction: column;
  }
  .most_often_rent_2022 .list > div img {
    margin-right: 0;
    margin-bottom: 18px;
  }
}
@media (max-width: 320px) {
  .glav_page,
  .header_2 {
    max-width: 320px;
  }
}
