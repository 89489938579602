@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-form {
  display: flex;
  flex-direction: column;

  &__input {
    height: 69px;
    background: #fff;
    border: 0;
    border-radius: 12px;
    padding: 0 24px;
    margin-top: 17px;

    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    /* identical to box height */
    display: flex;
    align-items: center;

    color: #a4a4a4;

    &:active,
    &:focus {
      outline: 1px solid theme.$acent-hover-color;
      // outline-color: ;
    }

    &:first-child {
      margin-top: 0;
    }
  }
  &__textarea {
    @extend .kw-form__input;
    padding-top: 24px;
    min-height: 105px;
    max-height: 100%;
  }
  &__attache {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 28px;
    margin-bottom: 25px;

    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #444444;
    cursor: pointer;
    overflow: hidden;

    position: relative;

    input[type="file"] {
      position: absolute;
      z-index: -1;
      opacity: 0;
      display: block;
      width: 0;
      height: 0;
    }
  }

  &__button {
    margin-bottom: 15px;
    padding-top: 5px;

    @include grid.md {
      padding-top: 0px;
    }
  }
}
