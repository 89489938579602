@use "sass:math";
@use "/src/styles/grid.scss";
@use "/src/styles/theme.scss";

// .progress-ring__circle {
//   // transition: 0.35s stroke-dashoffset;
//   // axis compensation
//   transform: rotate(-90deg);
//   transform-origin: 50% 50%;

//   $circumference: 108 * math.$pi;
//   $offset: theme.$circumference - math.div(90, 100) * $circumference;

//   stroke-dasharray: theme.$circumference, $circumference;
//   stroke-dashoffset: theme.$offset;
// }

.kw-statblock {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(15px);
  @-moz-document url-prefix() {
    background: rgba(28, 27, 27, 0.91);
  }

  border-radius: 20px;
  height: 100%;

  padding: 10px 55px 30px 35px;

  @include grid.md {
    padding: 25px 62px 78px;
  }

  @include grid.lg {
    max-width: 674px;
    padding: 25px 41px;
    min-height: 337px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include grid.md {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 10px;
    }
  }
  &__chart {
    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 700;

    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    width: 130px;
    height: 130px;
    @include grid.md {
      font-size: 26px;
      line-height: 34px;
      width: 190px;
      height: 190px;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &._98 {
      background-image: url(/assets/img/chart_98.png?as=webp);
    }
    &._45 {
      background-image: url(/assets/img/chart_45.png?as=webp);
    }
    &._95 {
      background-image: url(/assets/img/chart_95.png?as=webp);
    }
  }

  &-item {
    display: flex;
    flex-direction: column;

    align-items: center;
  }

  &__title {
    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    @include grid.md {
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.7px;
    }

    color: rgba(255, 255, 255, 0.7);
  }
}
