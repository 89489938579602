@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Helvetica/helvetica_regular.otf?as=woff2") format("woff2"),
    url("/assets/fonts/Helvetica/helvetica_regular.otf?as=woff") format("woff"),
    url("/assets/fonts/Helvetica/helvetica_regular.otf?as=ttf") format("truetype");
}
@font-face {
  font-family: "Lack";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Lack/Lack.ttf?as=woff2") format("woff2"),
    url("/assets/fonts/Lack/Lack.ttf?as=woff") format("woff"),
    url("/assets/fonts/Lack/Lack.ttf") format("truetype");
}
@font-face {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Mont/Mont-Bold.woff2") format("woff2"),
    url("/assets/fonts/Mont/Mont-Bold.woff") format("woff"),
    url("/assets/fonts/Mont/Mont-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Mont";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Mont/Mont-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/Mont/Mont-SemiBold.woff") format("woff"),
    url("/assets/fonts/Mont/Mont-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Mont";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Mont/Mont-Regular.woff2") format("woff2"),
    url("/assets/fonts/Mont/Mont-Regular.woff") format("woff"),
    url("/assets/fonts/Mont/Mont-Regular.ttf") format("truetype");
}

.kw-icon {
  font-family: santeh-import !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &_help {
    &::after {
      content: "\e90d";
    }
  }
}
