.kw-attache-svg {
    width: 23px;
    height: 24px;
}

.kw-bezvivoz_title-svg {
    width: 50px;
    height: 50px;
}

.kw-browse_gallery-svg {
    width: 24px;
    height: 18px;
}

.kw-call-svg {
    width: 26px;
    height: 26px;
}

.kw-car_left-svg {
    width: 22px;
    height: 18px;
}

.kw-car_right-svg {
    width: 43px;
    height: 25px;
}

.kw-check-svg {
    width: 16px;
    height: 16px;
}

.kw-checkmark-svg {
    width: 12px;
    height: 11px;
}

.kw-download-svg {
    width: 22px;
    height: 22px;
}

.kw-gmail_apps_script-svg {
    width: 26px;
    height: 26px;
}

.kw-location-svg {
    width: 24px;
    height: 25px;
}

.kw-map-svg {
    width: 19px;
    height: 24px;
}

.kw-map-pin-svg {
    width: 20px;
    height: 20px;
}

.kw-percent-svg {
    width: 107px;
    height: 100px;
}

.kw-redcar-svg {
    width: 52px;
    height: 30px;
}

.kw-reload-svg {
    width: 14px;
    height: 12px;
}

.kw-s10_car-svg {
    width: 20px;
    height: 20px;
}

.kw-s10_user-svg {
    width: 20px;
    height: 20px;
}

.kw-small_star-svg {
    width: 16px;
    height: 15px;
}

.kw-sms-svg {
    width: 18px;
    height: 19px;
}

.kw-star-svg {
    width: 24px;
    height: 22px;
}

.kw-tg-svg {
    width: 54px;
    height: 54px;
}

.kw-timer-start-svg {
    width: 16px;
    height: 16px;
}

.kw-vb-svg {
    width: 54px;
    height: 53px;
}

.kw-vivoz_title-svg {
    width: 50px;
    height: 50px;
}

.kw-wa-svg {
    width: 54px;
    height: 53px;
}

.kw-x-svg {
    width: 12px;
    height: 12px;
}

.kw-youtube-svg {
    width: 42px;
    height: 29px;
}

