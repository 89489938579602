// @use "sass:math";
@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/numlist-card/numlist-card.scss";

.kw-s7 {
  order: 70;

  @include theme.section-padding;

  background-image: url(/assets/img/s7_bg.png?as=webp);
  background-repeat: no-repeat;
  background-position: 0 57%;
  background-size: 105%;

  display: none;
  @include grid.md {
    display: flex;
  }

  &__header {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 59px;
  }
  &__title {
    position: relative;
    br {
      display: none;

      @include grid.md {
        display: unset;
      }
    }

    &:after {
      content: "";
      background-image: url(/assets/icons/s7/s7_question.png?as=webp);
      background-repeat: no-repeat;
      background-position: right;
      background-size: cover;
      display: block;
      width: 134px;
      height: 110px;
      position: absolute;
      top: -45px;
      right: -235;
    }
  }
}
