@use "/src/styles/grid.scss";
@use "/src/styles/theme.scss";

/* The container must be positioned relative: */
.kw-select {
  select {
    box-sizing: border-box;
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    height: 100%;
    width: 100%;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    font-family: "Mont", sans-serif;
    font-style: normal;
    font-weight: 600;

    font-size: 12px;
    line-height: 13px;
    @include grid.lg {
      font-size: 14px;
      line-height: 13px;
    }

    color: #fff;

    &::-ms-expand {
      display: none;
    }

    option,
    select option,
    select:focus > option,
    select > option:focus,
    select > option:active,
    select:focus > option:checked {
      font-size: 16px;
      background: #000 !important;
      color: #fff !important;
    }

    // &:focus + .focus {
    &:hover + .focus {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 2px solid theme.$input-txt-color;
      border-radius: inherit;
      pointer-events: none;
    }
  }
  position: relative;
  min-width: 100px;
  height: 35px;

  @include grid.lg {
    height: 52px;
  }

  border: 0;
  border-radius: 1000px;

  padding: 0 30px;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;

  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(15px);
  @-moz-document url-prefix() {
    background-color: theme.$input-bg-color;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    width: 9px;
    height: 9px;
    border: 2px solid #ffffff;
    border-left: 0;
    border-top: 0;
    transform: rotate(45deg);
    margin-bottom: 4px;
  }
}
