@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/styles/fonts.scss";
@use "/src/components/online-badge/style.scss";
@use "/src/chunks/nav/nav.scss";

@use "/src/components/legacy/sticky_header.scss";

.kw-header {
  &__top {
    background-color: theme.$base-grey-bg;
    &__wrapper {
      list-style: none;
      padding: 0px;

      display: flex;
      flex-direction: row;
      align-items: stretch;

      height: 30px;
    }
    &__item {
      border-right: 1px solid #dadada;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex: 1 1 auto;

      &:last-child {
        border: 0;
      }
    }
    &__link {
      text-decoration: none;
      font-family: theme.$font-mont;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
      text-align: center;
      color: theme.$base-link-color;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
      height: 100%;
      transition: theme.$header-transition-hover;
      &:hover {
        background-color: theme.$base-link-color;
        color: #fff;
      }

      span {
        display: none;
        @include grid.lg {
          display: inline;
        }
      }
    }
  }
  &__logo {
    width: 99px;
    max-width: 100%;
    @include grid.lg {
      width: 234px;
    }

    &-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      align-items: center;
      @include grid.lg {
        display: flex;
      }
    }
  }
  &__txt {
    font-family: "Helvetica", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;

    @include grid.lg {
      font-size: 14px;
      line-height: 19px;
    }
    color: theme.$base-header-color;
  }
  &__wrapper {
    display: flex;

    align-items: stretch;
    justify-content: flex-start;

    flex-direction: column;
    padding-top: 14px;
    padding-bottom: 14px;
    @include grid.lg {
      flex-direction: row;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    box-sizing: content-box;
    min-height: 135px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;

    @include grid.lg {
      margin-left: 35px;
    }

    &_cta {
      @extend .kw-header__item;
      @include grid.lg-max {
        margin-top: 20px;
        margin-bottom: 15px;
        align-items: center;
        gap: 6px;
      }
    }

    &:nth-child(2) {
      margin-left: 30px;
      justify-content: flex-end;
    }
    &:last-child {
      justify-content: flex-end;
      margin-left: 18px;
      flex: 1;
    }
  }

  .location-list {
    display: flex;
    flex-direction: row;
    margin-top: 23px;

    &__item {
      display: flex;
      align-items: center;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
  .phone-list {
    @include grid.lg-max {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
    }

    &__item {
      text-align: center;

      @include grid.lg {
        text-align: left;
      }
      a {
        font-family: "Helvetica", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;

        @include grid.lg {
          font-size: 17px;
          line-height: 23px;
        }

        text-decoration: none;
        color: theme.$base-header-color;
        span {
          color: theme.$acent-header-color;
        }
      }
    }
  }
  .contacts-link {
    text-decoration: none;
    display: flex;
    align-items: center;

    @include grid.lg-max {
      display: none;
    }

    &_mob {
      @extend .contacts-link;
      display: flex;
      @include grid.lg {
        display: none;
      }
    }

    & > span {
      font-family: theme.$font-mont;
      font-style: normal;
      font-weight: 700;
      text-decoration: none;
      font-size: 14px;
      line-height: 16px;
      color: #3a3a3c;

      margin-left: 6px;
      @include grid.lg {
        font-weight: 400;
        text-decoration: underline;
        margin-left: 12px;
        color: theme.$grey-header-color;
      }
    }
  }
  .time-link {
    text-decoration: none;
    display: flex;
    align-items: center;

    span {
      font-family: "Arial";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: theme.$grey-header-color;

      margin-left: 7px;
      margin-bottom: 7px;
    }
  }
  .nocall {
    display: flex;
    align-items: center;

    justify-content: space-around;

    @include grid.lg {
      justify-content: space-evenly;
      background: #f7f7f7;
      height: 53px;
      border-radius: 5px;
    }

    @include grid.lg-max {
      .social-list__item {
        &:last-child {
          display: none;
        }
      }
    }

    & > span {
      font-family: "Helvetica", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: theme.$base-header-color;
    }

    &-list {
      display: flex;
      flex-direction: row;
      &__item {
        margin-left: 9px;
      }
    }
  }
  .btn-grade {
    &:hover {
      background: theme.$checkbox-hover-color;
    }
    border: 0;
    background: theme.$checkbox-checked-color;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;

    /* identical to box height */
    text-transform: uppercase;

    color: #ffffff;
    height: 53px;
    width: 100%;
    margin-bottom: 22px;

    @include grid.xl {
      width: 200px;
    }

    &_menu,
    &_call {
      @extend .btn-grade;
      padding: 0;
      margin: 0px;
      height: 37px;
      border-radius: 4px;
      font-size: 10px;
      line-height: 12px;

      @include grid.lg {
        display: none;
      }
    }

    &_call {
      background: theme.$acent-header-color;
    }

    &_menu {
      background: theme.$base-link-color;
    }
  }
}
