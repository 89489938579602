@use "/src/styles/grid.scss";
@use "/src/styles/theme.scss";

.kw-review {
  &-block {
    // display: grid;
    // gap: 20px;

    .kw-video {
      background: #ffffff;
      box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.06);
    }

    // @include grid.md {
    //   grid-template-columns: repeat(2, 1fr);
    //   grid-auto-flow: dense;
    //   & > * {
    //     &:nth-child(3n) {
    //       @include grid.lg-max {
    //         grid-column: 2 / 2;
    //       }
    //     }
    //   }
    // }

    // @include grid.lg {
    //   grid-template-columns: repeat(3, 1fr);
    // }
  }

  &-text {
    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 400;
    color: rgba(58, 58, 60, 0.7);
    background: #ffffff;
    box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 12px;
    line-height: 16px;
    padding: 20px 24px;
    @include grid.md {
      font-size: 16px;
      line-height: 21px;
      padding: 30px;
    }

    &__content {
      overflow: scroll;
    }

    &__avatar {
      width: 22px;

      @include grid.md {
        width: 55px;
      }
    }
    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      margin-top: 25px;

      @include grid.md {
        margin-top: 15px;
      }

      .name {
        font-weight: 700;
        color: theme.$base-link-color;
        font-size: 12px;
        line-height: 17px;

        @include grid.md {
          font-size: 18px;
          line-height: 1;
        }
      }
      .score {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        @include grid.md {
          padding-top: 4px;
        }

        svg {
          width: 16px;
          height: 16px;

          @include grid.md {
            width: 20px;
            height: 20px;
          }
        }

        & > .number {
          font-weight: 500;
          color: theme.$base-header-color;
          margin-top: 4px;

          @include grid.md {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
