@use "/src/styles/grid.scss";
.social-list {
  display: flex;
  align-items: center;
  justify-content: center;

  &_v {
    @extend .social-list;
    column-gap: 16px;

    @include grid.lg {
      flex-direction: column;
      row-gap: 14px;
    }
  }

  &_h {
    @extend .social-list;
    flex-direction: row-reverse;
    column-gap: 6px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
    }
  }
}
