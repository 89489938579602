.slider-arrow {
  width: 59px;
  height: 59px;
  border: 0;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.08);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:after {
    content: "";
    display: block;
    width: 13px;
    height: 13px;
    border: 2px solid #000000;
    transform: rotate(45deg);
  }
  &_right {
    @extend .slider-arrow;
    &:after {
      margin-right: 6px;
      border-left: 0;
      border-bottom: 0;
    }
  }
  &_left {
    @extend .slider-arrow;
    &:after {
      margin-left: 6px;
      border-top: 0;
      border-right: 0;
    }
  }
}
