@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/description-list/description-list.scss";

.kw-s4 {
  order: 50;
  justify-content: flex-end;
  display: flex;

  padding-top: 80px;
  padding-bottom: 520px;

  @include grid.md {
    padding-bottom: 670px;
  }
  @include grid.lg {
    padding-bottom: 800px;
  }


  @include grid.xl {
    padding-top: 130px;
    padding-bottom: 159px;
  }
  position: relative;
  &:before,
  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    bottom: 0;
    background-repeat: no-repeat;
    display: none;
    height: 100%;
    width: 100%;
    @include grid.xl {
      display: block;
      width: 50%;
      background-size: contain;
    }
  }
  &:before {
    left: 0;
    bottom: 0;
    background-position: 0 100%;
    background-image: url(/assets/img/tagcloud_sm_full.png?as=webp);
    background-size: 100%;

    display: block;
    @include grid.md {
      background-image: url(/assets/img/tagcloud_md_full.png?as=webp);
    }
    @include grid.xl {
      background-image: url(/assets/img/tagcloud_lg_full.png?as=webp);
    }
  }
  &:after {
    right: 0;
    background-image: url(/assets/img/s4bg2.png?as=webp);
    background-position: 100% 102%;
  }

  &__header {
    position: relative;
    &:after {
      content: "";
      background-image: url(/assets/img/questions.png?as=webp);
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      display: block;
      position: absolute;

      width: 91px;
      height: 77px;
      right: -25px;
      bottom: 0;
      @include grid.lg {
        width: 134.82px;
        height: 110px;
        right: -60px;
        bottom: -25px;
      }
    }
    br {
      @include grid.lg {
        display: none;
      }
    }
  }

  &__description-list {
    margin-top: 40px;

    display: grid;
    grid-template-columns: 1fr 1fr;

    // row-gap: 40px;
    // column-gap: 22px;
  }
  &__pagination {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
}
