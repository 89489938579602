@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.numlist-card {
  background: #ffffff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  margin-bottom: 75px;
  &:last-child {
    margin-bottom: 0px;
  }

  font-family: theme.$font-mont;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #444444;

  padding: 47px 30px;
  padding-top: 0;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 47px;
    margin-top: -25px;
  }

  &__h {
    font-weight: 700;
    font-size: 28px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 22px;

    br {
      display: none;

      @include grid.lg {
        display: unset;
      }
    }

    color: theme.$base-header-color;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include grid.lg {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &-item {
      display: flex;
      justify-content: stretch;
      align-items: center;
      row-gap: 13px;
      column-gap: 30px;

      flex-direction: column;
      @include grid.lg {
        flex-direction: row;
      }
    }
  }

  counter-reset: section;
  &__text {
    display: flex;
    flex: 1 1 0;
    text-align: center;

    @include grid.lg {
      text-align: left;
    }
  }
  &__icon {
    border: 2px solid #e2e2e2;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1 1 auto;
    max-width: 76px;
    width: 100%;
    height: 76px;

    position: relative;

    &::after {
      counter-increment: section;
      content: counter(section);
      position: absolute;
      width: 28px;
      height: 28px;
      left: calc(50% - 28px / 2 + 29px);
      top: calc(50% - 28px / 2 - 29px);

      background: #e36052;
      border-radius: 14px;

      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      max-width: 44px;
      max-height: 40px;
    }
  }
}
