// @use "sass:math";
@use "/src/chunks/review/review.scss";
@use "/src/styles/grid.scss";
@use "/src/components/kw-video/kw-video.scss";

.kw-s8 {
  order: 80;
  padding-bottom: 120px;
  background: #f1f1f1;

  &__controls {
    display: none;

    @include grid.md {
      display: flex;
    }
    flex-direction: row;
    gap: 26px;
    justify-content: center;
    margin-top: 20px;
  }
  &__arrow {
    @include grid.lg {
      position: absolute;
      top: 50%;
    }

    &_left {
      @extend .kw-s8__arrow;
      @include grid.lg {
        left: -110px;
      }
    }
    &_right {
      @extend .kw-s8__arrow;
      @include grid.lg {
        right: -110px;
      }
    }
  }

  &__swiper-wrapper {
    position: relative;
  }

  &__review-wrap {
    overflow: hidden;
  }
  &__pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .kw-video {
    picture {
      overflow: hidden;
      height: 274px;
      width: 100%;
      display: flex;
      justify-content: center;
        position: relative;
    }
    img {
      height: fit-content;
      width: auto;
    }

    @include grid.md {
      picture {
        height: 402px;
      }
      img {
        position: absolute;
        width: auto;
        max-width: unset;
        height: 100%;
        top: 0;
      }
    }
    @include grid.xl {
      picture {
        height: 360px;
        max-width: 377px;
      }
    }
  }
  .kw-review-text {
    height: 274px;
    box-sizing: border-box;
    overflow: hidden;

    @include grid.md {
      max-height: 402px;
      height: 402px;
    }
    @include grid.xl {
      max-height: 360px;
    }
  }

  &__info-line {
    background: linear-gradient(0deg, #3a3a3c, #3a3a3c);

    font-family: "Mont";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;

    text-align: center;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;

    padding: 16px;
    margin-bottom: 58px;

    .number {
      font-weight: 400;
    }

    .icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 6px;
      }
    }
  }

  &__header {
    margin-bottom: 30px;

    @include grid.md {
      margin-bottom: 45px;
    }
  }
}
