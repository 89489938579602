@use "sass:math";
@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-s5 {
  display: flex;
  order: 40;

  @include grid.md {
    order: 51;
  }

  background: #f1f1f1;
  @include theme.section-padding;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
    text-align: center;

    @include grid.lg {
      text-align: left;
    }
  }

  &__card_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include grid.md {
      display: grid;
      grid-template-columns: 1fr 1fr;
      .kw-card {
        order: 1;
        &:nth-child(1n + 3) {
          order: 4;
        }
        &:nth-child(3) {
          order: 2;
        }
        &:nth-child(2) {
          order: 3;
          grid-column-start: 1;
          grid-column-end: 3;

          .kw-card__list-item {
            max-width: calc(math.div(100, math.div(2, 1)) * 1% - 41px);
          }
        }
      }
    }
    @include grid.lg {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .kw-card {
        &:nth-child(1n + 1) {
          order: 1;
        }
        &:nth-child(2) {
          grid-column-start: 2;
          grid-column-end: 4;

          .kw-card__list-item {
            max-width: calc(math.div(100, math.div(2, 1)) * 1% - 54px);
          }
        }
      }
    }
  }
}
