@use "/src/styles/grid.scss";
@use "/src/styles/theme.scss";

.kw-button {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #ffffff;

  font-size: 12px;
  line-height: 13px;

  border: 0;
  height: 40px;
  @include grid.md {
    height: 52px;
    font-size: 14px;
    line-height: 13px;
  }

  background: theme.$acent-header-color;
  border-radius: 1000px;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  outline: none;

  cursor: pointer;
  &:hover {
    background-color: theme.$acent-hover-color;
  }
}
