@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/other/titles.scss";

.portfolio-slide {
  background: #ffffff;
  box-shadow: 0px 4px 52px rgba(7, 34, 76, 0.05);
  // width: 773px;
  // max-width: 100%;
  max-height: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 700px;

  &__bg-block {
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    flex: 1 1 auto;
    flex-basis: 200px;
  }

  &__content {
    .scroll-wrap {
      overflow: scroll;
    }
    max-height: calc(100% - 230px);
    // overflow-y: scroll;
    overflow: hidden;

    padding: 30px;
    // padding-right: 54px;
    box-sizing: border-box;

    font-family: theme.$font-mont;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    display: flex;
    flex: 1 1 auto;

    color: #3a3a3c;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(58, 58, 60, 0.7);

    ul {
      list-style: disc;
      margin-left: 22px;
    }
  }

  &__h3 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 40px;
    margin-top: 25px;
  }

  &__preview {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__list {
    svg {
      margin-right: 6px;
    }
    span {
      font-weight: 500;
    }

    li.item {
      &_icon {
        display: flex;
        align-items: center;
      }
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    &_p {
      @extend .portfolio-slide__list;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    &_s {
      @extend .portfolio-slide__list;

      li.item {
        &:first-child {
          font-weight: 400;
          margin-left: 0px;
        }
        margin-bottom: 6px;
        margin-left: 26px;
      }
    }
  }
}
