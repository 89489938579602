@use "sass:math";
@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-card {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;

  &_cta {
    flex-direction: column;
    // height: 100%;

    @include grid.lg {
      height: 420px;
    }

    // @for $i from 1 through 6 {
    //   &:nth-child(#{$i}) {
    //     header {
    //       background-image: url("/assets/img/s2_" + $i + ".jpg");
    //     }
    //   }
    // }
  }
  &_pl {
    flex-direction: column;
    .kw-card {
      &__icon {
        display: block;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: cover;
        position: absolute;
        right: 0;

        width: 60px;
        height: 60px;
        @include grid.md {
          width: 80px;
          height: 80px;
        }
      }
      &__h {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 0;
        max-width: 287px;

        padding-right: 85px;
      }
    }
    header {
      justify-content: center;
      height: 80px;

      @include grid.md {
        height: 107px;
        padding: 0px 32px;
      }

      @include grid.lg {
        padding: 0px 45px;
      }
    }
    // @for $i from 1 through 9 {
    //   &:nth-child(#{$i}) {
    //     header {
    //       background-image: url("/assets/img/s5/s5b" + $i + ".png?as=webp");
    //     }
    //     .kw-card__h {
    //       &:after {
    //         background-image: url("/assets/img/s5/s5c" + $i + ".png?as=webp");
    //       }
    //     }
    //   }
    // }
  }

  &__body {
    padding: 24px;

    @include grid.lg {
      padding: 0 30px;
      padding-top: 25px;
      padding-bottom: 35px;
    }

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
  }

  &__header {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-blend-mode: multiply;
    display: flex;

    box-sizing: border-box;
    height: 80px;
    padding: 0 24px;
    align-items: center;

    @include grid.md {
      padding: 22px 32px;
      height: 95px;
      padding-right: 98px;
    }

    &._center {
      align-items: center;
    }
  }

  &__h {
    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;

    @include grid.md {
      font-size: 20px;
      line-height: 26px;
    }

    color: #ffffff;
  }

  &__list {
    &._pl {
      display: flex;
      flex-direction: column;

      @include grid.md {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        column-gap: 82px;
      }
      @include grid.lg {
        column-gap: 108px;
      }
    }
    &-item {
      font-family: theme.$font-mont;
      font-style: normal;
      font-weight: 500;

      font-size: 14px;
      line-height: 18px;
      @include grid.md {
        font-size: 15px;
        line-height: 20px;
      }

      /* identical to box height */

      color: #312f30;

      display: flex;
      justify-content: flex-start;
      align-content: center;

      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0px;
      }

      &._pl {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;

        display: flex;
        // flex-basis: calc(math.div(100, math.div(2, 1)) * 1% - 54px);
        // min-width: 288px;
        justify-content: space-between;
        flex-grow: 1;
        column-gap: 20px;
        // flex: 1 1 auto;
        // max-width: 288px;
        // flex-basis: 50%;
        //

        .price {
          font-weight: 500;
          color: rgba(49, 47, 48, 0.5);
          &:after {
            content: "₽";
            font-weight: 700;
            color: theme.$acent-header-color;
            margin-left: 2px;
          }
        }
      }

      svg {
        margin-right: 14px;
      }
    }
  }
  &__button {
    text-decoration: none;

    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 700;
    text-align: center;

    font-size: 14px;
    line-height: 13px;

    color: theme.$acent-header-color;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid theme.$acent-header-color;
    border-radius: 40px;
    height: 40px;

    margin-top: 20px;
    @include grid.lg {
      height: 49px;
    }
  }
}
