@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/chunks/portfolio/portfolio.scss";
@use "/src/components/slick/arrow.scss";

.kw-s10 {
  order: 100;
  background: #f6f6ff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include theme.section-padding;

  &__header {
    margin-bottom: 50px;
    @include grid.md {
      max-width: 606px;
    }
  }

  &__slider {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: nowrap;
    // gap: 20px;
    width: 100%;
    padding: 0 24px;
  }
  &__pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    @include grid.xl {
      display: none;
    }
  }

  &__control {
    display: none;
    flex-direction: row;
    margin-top: 30px;
    gap: 26px;
    @include grid.xl {
      display: flex;
    }
  }
}
