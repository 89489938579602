@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-s9 {
  order: 90;
  padding-top: 80px;
  padding-bottom: 260px;

  @include grid.md {
    padding-top: 120px;
    padding-bottom: 580px;
  }

  @include grid.lg {
    padding-top: 157px;
    padding-bottom: 288px;
  }

  @include grid.xxl {
    background-image: url(/assets/img/s9_trash.png?as=webp);
  }
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: auto 50%;

  position: relative;
  &:after {
    content: "";
    position: absolute;
    display: block;

    right: 0;
    bottom: 0;
    width: 100%;
    background-size: contain;
    background-position: 100% 100%;
    height: 407px;

    @include grid.md {
      height: 622px;
    }

    @include grid.lg {
      top: 0;
      width: 50%;
      height: 100%;
    }
    background-repeat: no-repeat;
    background-image: url(/assets/img/s9_hello_kamaz.png?as=webp);
  }

  &__header {
    @include grid.md {
      padding-right: 130px;
    }

    @include grid.lg {
      padding-right: 0px;
    }
  }
  &__content {
    margin-top: 15px;

    font-family: theme.$font_mont;
    font-style: normal;
    font-weight: 500;

    font-size: 14px;
    line-height: 18px;
    @include grid.md {
      font-size: 16px;
      line-height: 21px;
    }

    color: rgba(68, 68, 68, 0.7);
  }

  &__subtitle {
    font-family: theme.$font_mont;
    font-style: normal;
    font-weight: 600;
    color: #e36052;

    font-size: 14px;
    line-height: 18px;
    @include grid.md {
      font-size: 22px;
      line-height: 29px;
    }

    display: flex;
    gap: 12px;
    align-items: center;

    margin-top: 20px;
  }
}
