@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-checkbox {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  color: #ffffff;

  font-size: 10px;
  line-height: 13px;

  display: flex;
  align-items: center;

  @include grid.lg {
    font-size: 12px;
    line-height: 1;
  }

  padding-left: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: relative;

  .label {
    min-height: 18px;
    display: flex;
    align-items: center;
  }
  /* identical to box height, or 142% */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: theme.$checkbox-checked-color;

      svg {
        opacity: 1;
      }
      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    // top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    svg {
      opacity: 0;
    }
  }
  &:hover :not(input:checked) ~ .checkmark {
    background-color: #ccc;
  }
  &:hover input:checked ~ .checkmark {
    background: theme.$checkbox-hover-color;
  }

  &_light {
    @extend .kw-checkbox;
    .checkmark {
      svg {
        fill: #fff;
      }
    }
  }

  &_dark {
    .checkmark {
      svg {
        fill: #ff9a00;
      }
    }
    input {
      &:checked ~ .checkmark {
        background-color: #fff;
      }
    }
    &:hover input:checked ~ .checkmark {
      background: #fff;
    }
    @extend .kw-checkbox;
    font-weight: 500;
    color: theme.$base-link-color;
  }

  // background: ;
  // border-radius: 2px;
}
