@use "/src/styles/grid.scss";

.kw-banks {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  @include grid.lg {
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    height: 77px;
    padding: 16px;

    @include grid.md {
      padding: 8px;
    }
    box-sizing: border-box;

    &:nth-child(1) {
      img,
      picture {
        max-height: 45px;

        @include grid.lg {
          max-height: 63px;
        }
      }
    }
    &:nth-child(2) {
      img,
      picture {
        max-height: 25px;
        @include grid.lg {
          max-height: 32px;
        }
      }
    }
    &:nth-child(3) {
      img,
      picture {
        max-height: 45px;
        @include grid.lg {
          max-height: 59px;
        }
      }
    }
    &:nth-child(4) {
      img,
      picture {
        max-height: 61px;
        @include grid.lg {
          max-height: 77px;
        }
      }
    }
    &:nth-child(5) {
      img,
      picture {
        max-height: 41px;
        @include grid.lg {
          max-height: 51px;
        }
      }
    }
    &:nth-child(6) {
      img,
      picture {
        max-height: 25px;
        @include grid.lg {
          max-height: 25px;
        }
      }
    }
    &:nth-child(7) {
      img,
      picture {
        max-height: 49px;
        @include grid.lg {
          max-height: 65px;
        }
      }
    }
    &:nth-child(8) {
      img,
      picture {
        max-height: 63px;
        @include grid.lg {
          max-height: 75px;
        }
      }
    }

    // img,
    // picture {
    //   height: 100%;
    // }

    @include grid.lg {
      height: 146px;
    }
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
