@use "/src/styles/grid.scss";
@use "/src/styles/theme.scss";

.kw-description-list {
  list-style: none;
  margin: 0px;
  padding: 0px;

  .item {
    font-family: theme.$font-mont;
    font-style: normal;
    position: relative;
    padding-top: 6px;

    &:before {
      content: "";
      display: block;
      width: 41.5px;
      height: 0px;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid theme.$acent-header-color;
    }

    &-title {
      font-family: inherit;
      font-style: inherit;
      font-weight: 600;
      margin-bottom: 8px;

      font-size: 16px;
      line-height: 21px;
      @include grid.lg {
        font-size: 18px;
        line-height: 24px;
      }
    }
    &-text {
      font-family: inherit;
      font-style: inherit;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      @include grid.lg {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.55px;
      }
    }
  }
}
