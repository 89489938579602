@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-s11 {
  order: 110;
  @include theme.section-padding;
  background: #000000;
  position: relative;

  padding-bottom: 328px;
  @include grid.md {
    padding-bottom: 463px;
  }

  @include grid.lg {
    padding-bottom: 120px;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0px;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 100%;

    background-image: url(/assets/img/s11/s11-bg_md.png?as=webp);
    @include grid.lg {
      width: 50%;
      background-size: cover;
      background-position: 0 0;
      top: 0;
      background-image: url(/assets/img/s11/s11-bg_lg.png?as=webp);
    }
  }
  &__container {
    @include grid.lg {
      position: relative;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 311px;
      background-position-x: calc(100% - 24px);

      @include grid.md {
        height: 463px;
        background-position-x: calc(100% - 103px);
      }

      @include grid.lg {
        bottom: -120px;
        height: calc(100% + 240px - 70px);
        width: 105%;
        background-position-x: 100%;
      }

      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(/assets/img/s11/men.png?as=webp);
    }
  }

  &__title {
    span {
      color: theme.$acent-header-color;
    }
  }

  &__header {
    margin-bottom: 25px;
  }

  &__footer {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: theme.$acent-header-color;

    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 30px;

    .name {
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
    }

    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;

      background-repeat: no-repeat;
      background-size: contain;

      width: 90px;
      height: 42px;
      bottom: -60px;
      left: 20px;

      background-image: url(/assets/img/s11/s11-array_sm.png?as=webp);
      @include grid.md {
        left: unset;
        bottom: unset;
        width: 220px;
        height: 80px;
        top: 80px;
        right: 40%;
        background-image: url(/assets/img/s11/s11-array_md.png?as=webp);
      }

      @include grid.lg {
        top: 0px;
        right: 0;
        background-image: url(/assets/img/s11/s11-array_xl.png?as=webp);
      }

      // transform: matrix(0.71, 0.71, 0.71, -0.71, 0, 0);
    }
  }
}
