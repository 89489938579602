@use "sass:math";
@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/card/card.scss";

.kw-s2 {
  display: flex;
  order: 20;
  background-image: url("/assets/img/s2bg.png?as=webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  @include theme.section-padding;

  // .swiper {
  //   overflow: hidden;
  // }
  // .swiper-slide {
  //   flex-shrink: 100 !important;
  //   width: 252px;
  //   height: 100%;
  // }
  &-pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    @include grid.md {
      margin-top: 40px;
    }

    @include grid.lg {
      display: none;
    }
  }

  // .swiper-wrapper {
  //   @include grid.md {
  //     // display: grid;
  //     // grid-template-columns: repeat(2, 1fr);
  //   }
  //   @include grid.lg {
  //     // display: block;
  //     // display: grid;
  //     // grid-template-columns: repeat(3, 1fr);
  //   }
  // }
  .swiper-slide {
    width: 252px;
    height: unset;
    display: flex;

    // @include grid.lg {
    //   // max-width: 377px;
    //   height: 100%;
    //   display: flex;
    // }
  }
  // .swiper-pagination {
  //   position: relative;
  //   margin-top: 20px;
  // }

  &__card {
    // margin-right: 20px;
    // margin-bottom: 20px;

    // flex-grow: 0; /* do not grow   - initial value: 0 */
    // flex-shrink: 0; /* do not shrink - initial value: 1 */
    // flex-basis: calc(
    //   math.div(100, math.div(2, 1)) * 1% - 20px * math.div(1, 2)
    // );

    width: 100%;
    flex: 1 1 0;
    // min-width: 340px;

    &_wrapper {
      // justify-content: center;

      // overflow: hidden;
      // height: 420px;

      // flex-wrap: wrap;
      // max-width: 100vw;
      //

      // // grid-template-columns: 1fr repeat(30%);
      // // grid-template-columns: 1fr 1fr 1fr;
      //
      // position: relative;
      // width: 100%;
      // height: 100%;
      // z-index: 1;
      // display: flex;
      // box-sizing: content-box;
      // // gap: 10px;
      // grid-template-columns: repeat(auto-fill, 252px);
      // grid-auto-flow: column;
      // @include grid.lg {
      //   display: grid;
      //   gap: 20px;
      //   grid-template-columns: repeat(auto-fill, 340px);
      // }
    }
  }
}
