@use "/src/styles/grid.scss";

.kw-video {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: relative;

  .kw-fig-subtitle {
    display: none;

    @include grid.md {
      display: flex;
    }
  }

  &__caption {
    &_left {
      @extend .kw-video__caption;
      left: 0;
      right: auto;
    }
    position: absolute;
    right: 0;
    bottom: 0;
    background: #000000;
    // padding: 16px 25px 15px 18px;
    padding-right: 27px;
    min-width: 150px;
    max-width: 100%;

    height: 60px;
    // width: 100%;
    @include grid.md {
      min-width: 214px;
      width: auto;
      height: 85px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);

    svg {
      width: 32px;
    }

    width: 60px;
    @include grid.md {
      width: 85px;
    }
  }

  .info-wrap {
    padding-left: 23px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
