@use "/src/styles/grid.scss";
@use "/src/styles/theme.scss";

.kw-nav {
  background-color: theme.$base-grey-bg;
  z-index: 100;

  @include grid.md-max {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  display: none;
  &.show {
  @include grid.md-max {
      position: fixed;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @include grid.lg {
    display: block;
    height: 50px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    flex: 1 1 aut0;
    justify-content: center;
    align-items: center;
    text-align: center;

    padding: 40px 0;

    .kw-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .kw-header__logo {
      width: 200px;
    }

    .kw-header__logo-wrap {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .phone-list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      &__item {
        display: flex;
        justify-content: center;

        &:last-child {
          .kw-button {
            background-color: theme.$input-bg-color;
          }
        }
      }
    }
    .kw-button {
      border-radius: 10px;
      width: 150px;
    }

    @include grid.md {
      display: none;
    }
  }

  &__close {
    position: absolute;

    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;

    cursor: pointer;
    padding: 10px;

    border: 0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include grid.lg {
      display: none;
    }
  }

  &-list {
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    justify-content: center;
    // height: 100%;
    @include grid.md {
      height: 100%;
    }

    @include grid.lg {
      max-width: 1440px;
      flex-direction: row;
    }

    &__item {
      transition: theme.$header-transition-hover;
      &:hover {
        background-color: theme.$base-link-color;
      }

      display: flex;
      flex: 1;
      align-items: stretch;
      justify-content: stretch;

      border: 1px solid theme.$input-txt-color;
      border-top: 0;
      padding: 15px;

      @include grid.lg {
        padding: 0px;
        border-right: 1px solid #ffffff;
      }

      &:last-child {
        border-right: none;
      }
      a {
        text-decoration: none;
        display: flex;
        padding: 0 10px;
        gap: 7px;

        align-items: center;
        justify-content: flex-start;
        @include grid.lg {
          justify-content: center;
          padding: 0 20px;
        }

        width: 100%;

        color: #312f30;

        &:hover {
          color: #fff;
        }

        .circle {
          border: 1px solid theme.$acent-header-color;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: theme.$base-grey-bg;
          width: 30px;
          height: 30px;
          flex: 0 0 30px;
        }

        span {
          font-family: "Lack", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-transform: capitalize;

          margin-left: 7px;
        }
      }
    }
  }
}
