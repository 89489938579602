@use "sass:math";
@use "/src/components/checkbox/checkbox.scss";
@use "/src/components/input/input.scss";
@use "/src/components/select/select.scss";
@use "/src/components/button/buttons.scss";
@use "/src/styles/grid.scss";
@use "/src/styles/theme.scss";

.kw-s1 {
  display: flex;
  order: 1;
  padding-top: 60px;
  padding-bottom: 80px;

  @include grid.lg {
    padding-top: 238px;
    padding-bottom: 238px;
  }

  background: url(/assets/img/main_bg.jpg?as=webp) no-repeat 0,
    0 rgba(0, 0, 0, 0.86);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: multiply;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  &__main-head {
    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 700;

    font-size: 22px;
    line-height: 29px;
    text-align: center;

    @include grid.md {
      font-size: 40px;
      line-height: 53px;
      text-align: left;
    }

    margin: 0px;
    margin-bottom: 12px;

    /* identical to box height */

    color: #ffffff;
  }
  &__sub-head {
    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    line-height: 18px;

    @include grid.md {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }

    margin: 0px;

    text-align: center;

    /* identical to box height */

    color: rgba(255, 255, 255, 0.6);
  }
  &__btn {
    margin-top: 38px;
    margin-bottom: 12px;

    width: 100%;
    @include grid.md {
      margin-top: 40px;
      margin-bottom: 18px;
      width: 274px;
    }
  }
  &__form {
    display: flex;
    flex: 0;

    flex-direction: column;
    row-gap: 5px;
    width: 100%;

    @include grid.md {
      // gap: 15px;
    }

    &-item {
      display: flex;
      flex: 1;
      flex-direction: column;
      position: relative;

      .input-error {
        color: red;
        margin-top: 10px;
        margin-bottom: 10px;
        display: none;
      }
    }
    &-body {
      display: flex;
      flex: 0;
      margin-top: 20px;
      flex-direction: column;
      row-gap: 5px;
      @include grid.md {
        margin-top: 33px;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
      }
      @include grid.lg {
        margin-top: 58px;
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .item {
      width: 100%;
      height: 35px;
      min-height: 35px;
      max-height: 35px;

      @include grid.md {
        display: flex;
        flex: 1 1 40%;
        height: 52px;
        min-height: 52px;
        max-height: 52px;
      }

      @include grid.lg {
        flex: 1;
      }

      @include grid.lg-max {
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 4;
        }
        &:last-child {
          order: 1;
        }
        &:first-child {
          order: 3;
        }
      }

      &:first-child {
        margin-left: 0px;
      }
    }
  }
}
