@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/banks/banks.scss";

.kw-s14 {
  order: 140;
  @include theme.section-padding;
  padding-bottom: 145px;

  &__header {
    margin-bottom: 30px;

    @include grid.lg {
      margin-bottom: 45px;
    }
  }
}
