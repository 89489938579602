@use "/src/styles/grid.scss";

@keyframes flash {
  50%,
  from,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
.online-badge {
  position: relative;
  padding-right: 10px;
  &:after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background: #00cd4a;
    border-radius: 50%;
    animation-name: flash;
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    position: absolute;
    right: 0px;

    top: calc(50% - 2.5px);
    @include grid.lg {
      top: 0;
    }
  }
}
