@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/faq/faq.scss";

.kw-s12 {
  order: 120;
  @include theme.section-padding;
  &__header {
    margin-bottom: 45px;
  }
  &__contacts {
    background: #ffffff;
    box-shadow: 0px 4px 52px rgba(7, 34, 76, 0.05);
    max-width: 629px;
    min-height: 226px;

    margin: 0px auto;
    margin-top: 40px;
  }

  .contacts {
    background-image: url(/assets/img/cta_bg_top.jpg?as=webp);
    background-repeat: no-repeat;
    background-position: 0 0;
    padding-top: 143px;
    background-size: 220px;

    @include grid.md {
      padding-top: 0px;
      background: none;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
    }
    &__body {
      padding: 32px 40px;
      font-size: 14px;
      line-height: 29px;
      color: #737373;
      a {
        text-decoration: none;
      }
    }
    &__head {
      margin-bottom: 12px;
    }

    &__pic {
      display: none;
      @include grid.md {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }
}
