@use "/src/styles/fonts.scss";
@use "/src/styles/normolize.scss";
@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/chunks/kwheader/kwheader.scss";
@use "/src/chunks/footer/footer.scss";

@use "/src/chunks/s1/s1.scss";
@use "/src/chunks/s2/s2.scss";
@use "/src/chunks/s3/s3.scss";
@use "/src/chunks/s4/s4.scss";
@use "/src/chunks/s5/s5.scss";
@use "/src/chunks/s6/s6.scss";
@use "/src/chunks/s7/s7.scss";
@use "/src/chunks/s8/s8.scss";
@use "/src/chunks/s9/s9.scss";
@use "/src/chunks/s10/s10.scss";
@use "/src/chunks/s11/s11.scss";
@use "/src/chunks/s12/s12.scss";
@use "/src/chunks/s13/s13.scss";
@use "/src/chunks/s14/s14.scss";

@use "/src/chunks/s15_map/s15_map.scss";

$themeColor: #3a3a3c;
@import "npm:swiper/swiper";
@import "npm:swiper/modules/pagination/pagination";
@import "npm:swiper/modules/grid/grid.scss";
@import "/src/styles/sprite.css";
@import "/assets/sprites_dist/symbol/sprite.scss";
// @import "npm:swiper/modules/navigation/navigation";

.kw-body {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

section {
  overflow: hidden;
}

footer {
  order: 1000;
  padding-top: 90px;
}
