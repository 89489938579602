@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-abovetitle {
  font-family: theme.$font-mont;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 6px;
  display: inline-block;

  font-size: 12px;
  line-height: 16px;

  @include grid.md {
    font-size: 14px;
    line-height: 18px;
  }

  color: theme.$acent-header-color;
}

.kw-fig {
  &-title {
    display: flex;

    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;

    font-size: 12px;
    line-height: 13px;

    @include grid.md {
      font-size: 14px;
      line-height: 13px;
    }
  }

  &-subtitle {
    display: flex;

    font-family: theme.$font-mont;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;

    color: rgba(255, 255, 255, 0.5);

    @include grid.md {
      font-weight: 600;
    }
  }

  &-time {
    font-family: theme.$font-mont;
    font-style: normal;

    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 12px;
    line-height: 1;

    span {
      margin-top: 2px;
      @include grid.md {
        margin-top: 0px;
      }
    }

    @include grid.md {
      font-weight: 600;
      font-size: 14px;
      line-height: 13px;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    svg {
      margin-right: 5px;
    }

    color: #ffffff;
  }
}

.kw-s-title {
  font-family: theme.$font-mont;
  font-style: normal;
  font-weight: 600;

  font-size: 22px;
  line-height: 29px;
  @include grid.md {
    font-size: 32px;
    line-height: 42px;
  }

  color: theme.$base-header-color;

  &_h3 {
    @extend .kw-s-title;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
  }
  &_h4 {
    @extend .kw-s-title;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }
  &._sub,
  &_sub {
    @extend .kw-s-title;
    color: rgba(68, 68, 68, 0.7);
    display: inline-block;
    margin-top: 15px;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    @include grid.md {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
    }

    &_light {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &_center {
    @extend .kw-s-title;
    text-align: center;
  }

  &._light,
  &_light {
    @extend .kw-s-title;
    color: #fff;
  }
}

.kw-s-text {
  font-family: theme.$font-mont;
  font-style: normal;
  font-weight: 500;

  font-size: 14px;
  line-height: 18px;
  @include grid.md {
    font-size: 16px;
    line-height: 21px;
  }

  &_light {
    @extend .kw-s-text;
    color: rgba(255, 255, 255, 0.7);
  }
}
