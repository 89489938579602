@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.kw-s15-map {
  overflow: unset;
  display: flex;
  flex-direction: column;
  order: 150;
  @include theme.section-padding;

  padding-bottom: 0px;
  @include grid.md {
    padding-bottom: 0px;
  }

  position: relative;

  &__ymap {
    min-height: 435px;
    background-image: url(/assets/img/load-map.jpg?as=webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include grid.lg {
      width: calc(50% - 80px);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__select {
    &_wrap {
      position: absolute;
      top: unset;
      bottom: calc(0px - 41px - 35px);
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 999;

      @include grid.lg {
        width: auto;
        display: block;
        bottom: calc(0px - 53px - 60px);
        left: 24px;
        height: 53px;
        bottom: unset;
        top: 35px;
      }
      @include grid.lg {
        left: calc(50% - 178px - 80px - 35px);
      }
    }

    height: 41px;
    background: #ff9a00;
    border: 0;
    border-radius: 12px;
    padding: 0px;

    select option,
    select:focus > option,
    select > option:focus,
    select > option:active,
    select:focus > option:checked {
      background: #ff9a00 !important;
      color: #fff !important;
    }

    @include grid.lg {
      height: 53px;
    }

    &::after {
      position: absolute;
      right: 28px;
      border: 0;
      transform: none;
      width: 22px;
      height: 18px;

      background-image: url(/assets/img/select_area.png?as=webp);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 100% 100%;
    }

    select {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      padding-left: 32px;
      padding-right: 66px;
      &:hover + .focus {
        border: 0;
        border-radius: inherit;
        pointer-events: none;
      }
    }
  }

  &__superblock {
    background: theme.$checkbox-checked-color;

    font-weight: 600;
    color: #ffffff;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    line-height: 21px;

    width: 100%;
    max-width: 100%;

    height: 40px;
    border-radius: 7px;
    margin-top: 15px;
    margin-bottom: 25px;

    @include grid.lg {
      margin-top: 25px;
      margin-bottom: 30px;
      border-radius: 12px;
      height: 60px;
      max-width: 336px;
      font-size: 32px;
      line-height: 42px;
    }
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    position: relative;

    padding-bottom: 80px;
    @include grid.lg {
      position: unset;
      padding-bottom: 60px;
    }

    @include grid.lg {
      padding-bottom: 132px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;

    display: block;
    height: 100%;
    width: 100%;

    @include grid.lg {
      width: calc(50% + 80px);
    }

    z-index: -1;

    background: url(/assets/img/main_bg.jpg?as=webp) no-repeat 0,
      0 rgba(0, 0, 0, 0.86);
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-blend-mode: multiply;
    background-size: cover;
  }

  &__body {
    color: #ffffff;
    padding: 24px;
    padding-bottom: 30px;

    @include grid.lg {
      padding: 30px 35px;
    }

    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(15px);
    @-moz-document url-prefix() {
      background: rgba(28, 27, 27, 0.91);
    }

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
  }

  &__h3 {
    font-weight: 700;

    font-size: 16px;
    line-height: 21px;

    @include grid.lg {
      font-size: 28px;
      line-height: 1;
    }
  }

  &__addres-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    font-weight: 500;

    font-size: 14px;
    line-height: 18px;
    @include grid.lg {
      font-size: 19px;
      line-height: 25px;
    }

    li {
      position: relative;
      padding-left: calc(24px + 8px);
      display: flex;
      align-items: center;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        top: 0px;
        left: 0px;

        background-image: url(/assets/img/location.png?as=webp);
        background-repeat: no-repeat;
      }
    }
  }
}
