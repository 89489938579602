@use "/src/styles/grid.scss";

.kw-footer {
  background: #000;
  bottom: 0;

  padding-top: 90px;
  padding-bottom: 112px;

  font-size: 14px;
  line-height: 18px;
  color: #c0c0c2;
}

footer {
  background-color: #1a1a1c;
  color: #fff;
  padding-top: 50px;
  width: 100%;
  padding-bottom: 35px;
}

.content.max {
  max-width: 100%;
}

.content.soc {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  grid-gap: 40px;
  margin-bottom: 70px;
  flex-wrap: wrap;
  height: 100%;
}

.content.soc > div {
  opacity: 1;
  transition: 0.3s;
  max-width: 180px;
  height: 100%;
  max-height: 100%;
}

.content.soc > div:hover {
  opacity: 1;
}

.content.soc > div > a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  position: relative;
  height: 100%;
}

.content.soc > div > a > img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.content.soc > div > a span.name {
  color: #eee;
  text-decoration: none;
  margin-top: 10px;
  text-align: center;
}

.content.soc > div > a span.desc {
  transition: 0.3s;
  border-radius: 4px;
  padding: 7px 8px;
  font-size: 13px;
  color: #c0c0c2;
  text-align: center;
  display: flex;
  justify-content: center;
  z-index: 2;
  opacity: 1;
  pointer-events: none;
  position: relative;
  margin-top: 45px;
  border: 1px solid #c0c0c2;
  height: 100%;
  max-height: 100%;
}

.content.soc > div > a span.desc::before {
  content: "";
  width: 1px;
  height: 35px;
  position: absolute;
  display: block;
  top: -35px;
  z-index: 1;
  background: #fff;
}
.content.cop {
  margin-top: 40px;
  display: grid;
  justify-content: center;
  text-align: center;
  grid-gap: 30px;
  align-items: center;
  padding-bottom: 30px;
  grid-template-columns: 1fr;
  @include grid.md {
    grid-template-columns: max-content max-content max-content;
  }
}

.content.cop a {
  color: #909092;
  text-decoration: none;
  transition: color 0.5s;
}

.content.cop a:hover {
  color: #ff9b00;
}

footer .content.fc {
  display: grid;
  grid-template-columns: 240px 1.5fr 1fr 1fr;
  grid-gap: 30px;
  max-width: 1400px;
}
footer .content.fc .logo {
  margin-bottom: 20px;
}
footer .content .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

footer .content p {
  color: #c0c0c2;
  line-height: 30px;
}

footer .content ul {
  list-style: none;
}

footer .content ul li {
  margin-bottom: 15px;
}

footer .content ul li a {
  color: #909092;
  text-decoration: none;
  transition: color 0.5s;
}

footer .content ul li a:hover {
  color: #ff9b00;
}

footer .content .contact ul {
  font-size: 14px;
  color: #c0c0c2;
}

footer .content .contact ul li {
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: left top;
  padding-left: 32px;
  margin-bottom: 15px;
}

footer .content .contact ul li.phone {
  background-image: url(http:/assets/app/img/icon/ico-phone.svg);
}

footer .content .contact ul li.email {
  background-image: url(http:/assets/app/img/icon/ico-email.svg);
}

footer .content .contact ul li.adres {
  background-image: url(http:/assets/app/img/icon/ico-home.svg);
}

footer .content .contact ul li.jobs {
  background-image: url(http:/assets/app/img/icon/ico-clock.svg);
}

footer .content .contact ul li > a {
  color: #c0c0c2;
  text-decoration: none;
}

footer .content .contact {
  margin-left: 50px;
}

@media (max-width: 1190px) {
  footer .content.fc {
    grid-template-columns: 1fr 1fr 1.5fr;
    grid-gap: 20px;
    max-width: 1190px;
  }
}

@media (max-width: 768px) {
  footer .content .about {
    grid-row: 2/3;
    grid-column: 1/4;
  }
  footer .content .contact {
    margin-left: 0;
  }
  footer .content {
    grid-template-columns: 1.5fr 1fr 1fr;
  }
}

@media (max-width: 640px) {
  footer .content.fc {
    grid-template-columns: 1fr 1fr 1.5fr;
    grid-gap: 20px;
    max-width: 1190px;
  }
  footer {
    padding-top: 60px;
    padding-bottom: 90px;
  }
  footer .content.fc {
    grid-template-columns: 1fr;
    grid-gap: 13px;
    font-size: 12px;
    padding: 0 30px;
  }
  footer .content .contact {
    margin-left: 0;
    grid-column: auto;
    grid-row: 3;
  }
  .content.soc > div:nth-child(3n) > a span.desc {
    right: 0;
  }
  .content.soc > div:nth-child(3n) > a span.desc::before {
    right: 42px;
  }
  footer .content .about {
    display: none;
  }
  .content.soc {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 20px 10px;
  }
  .content.soc > div > a span.desc {
    font-size: 12px;
  }
  .text_baner .content {
    grid-template-columns: 1fr;
  }
  footer .content .title {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
