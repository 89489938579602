@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/form/form.scss";
@use "/src/components/social/social.scss";

.kw-s13 {
  order: 130;
  background: #f6f6ff;
  @include theme.section-padding;

  @include grid.md {
    padding-top: 80px;
    padding-bottom: 0px;
    // padding-bottom: 756px;
  }

  @include grid.lg {
    padding-bottom: 120px;
  }

  &__container {
    position: relative;
    @include grid.md {
      padding-bottom: 786px;
    }
    @include grid.lg {
      padding-bottom: 0px;
    }
    &:after {
      content: "";
      position: absolute;
      // flex: 1 1 auto;

      height: 746px;
      bottom: 0px;

      pointer-events: none;

      width: 100%;

      display: none;
      @include grid.md {
        display: block;
      }
      @include grid.lg {
        width: calc(100% + 172px);
        bottom: -120px;
        left: 0px;
        height: calc(100% + 240px - 72px);
      }

      background-repeat: no-repeat;
      background-position-x: 100%;
      background-size: contain;
      background-image: url(/assets/img/smatphone.png?as=webp);
    }
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    color: rgba(58, 58, 60, 0.7);

    margin-top: 15px;
    margin-bottom: 20px;

    text-align: center;
    br {
      margin-bottom: 20px;

      @include grid.md {
        margin-bottom: 30px;
      }

      @include grid.lg {
        margin-bottom: 0px;
      }
    }

    @include grid.md {
      margin-bottom: 30px;
    }

    @include grid.lg {
      margin-bottom: 40px;
      max-width: 575px;
      text-align: left;
    }
  }

  &__title {
    text-align: center;

    @include grid.lg {
      text-align: left;
    }
  }

  &__btn {
    max-width: 317px;
  }

  &__form {
    max-width: 397px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      flex-direction: column-reverse;

      row-gap: 25px;

      @include grid.lg {
        flex-direction: row;
        padding-right: 25px;
      }
    }
  }

  &__social-list {
    position: relative;
    &:before {
      @include grid.lg {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 140px;
        right: 20px;
        bottom: 100%;
        background-image: url(/assets/img/form_arrow.png?as=webp);
        background-repeat: no-repeat;
      }
    }
  }
}
