// @use "sass:math";
@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";
@use "/src/components/statblock/statblock.scss";

.kw-s6 {
  order: 60;
  @include theme.section-padding;

  background: url(/assets/img/main_bg.jpg?as=webp) no-repeat 0,
    0 rgba(0, 0, 0, 0.86);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-blend-mode: multiply;

  &__header {
    position: relative;
    margin-bottom: 45px;
    &:after {
      content: "";
      background-image: url(/assets/img/precent.png?as=webp);
      background-repeat: no-repeat;
      background-position: right;
      width: 107;
      height: 100px;
      position: absolute;
      top: -25px;
      right: 0;

      display: none;

      @include grid.md {
        display: block;
      }
    }
  }

  &__card {
    margin-top: 25px;
    margin-bottom: 35px;
    li {
      color: rgba(255, 255, 255, 0.7);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      @include grid.md {
        font-size: 18px;
        line-height: 23px;
      }
    }
  }

  &__btn {
    @include grid.md {
      max-width: 150px;
    }
  }

  &__listblock {
    margin-top: 30px;
    @include grid.md {
      margin-top: 40px;
    }
    @include grid.lg {
      margin-left: 55px;
      margin-top: 0px;
    }
  }
}
