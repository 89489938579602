@use "/src/styles/grid.scss";

$font-mont: "Mont", sans-serif;
$base-grey-bg: #eeeeee;
$base-link-color: #3a3a3c;

$base-header-color: #444444;
$acent-header-color: #ff9a00;
$acent-hover-color: #ffad30;
$grey-header-color: #6b6b6b;
$header-transition-hover: 0.3s;

$input-bg-color: #333232;
$input-txt-color: #6e6b6a;

$checkbox-checked-color: #dc4e3f;
$checkbox-hover-color: #da6559;
// search code

.kw-callback-msg {
  display: inline-block;
  max-width: 100%;
  padding: 40px;
  font-family: $font-mont;
  font-size: 24px;
  font-weight: 500;
  background-color: #fff;
  color: $base-link-color;
  &.error {
    background-color: $checkbox-checked-color;
    color: #fff;
  }
}

.kw-svg {
  display: inline-block;
  stroke-width: 0;
  width: 16px;
  height: 16px;
}

#kw-svg-fire {
  path {
    &:first-child {
      fill: #e7563d;
    }
    &:last-child {
      fill: #ffc737;
    }
  }
}

@mixin section-padding {
  padding-top: 80px;
  padding-bottom: 80px;
  @include grid.md {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

dialog::backdrop {
  background: rgba(94, 110, 141, 0.9);
}

.kw-noscroll {
  overflow: hidden;
}

.kw-dialog {
  border: 0;
  outline: none;
  background-color: $base-grey-bg;

  .kw-form__input,
  .kw-form__textarea {
    @include grid.md-max {
      font-size: 14px;
    }
  }

  padding: 30px 24px;
  @include grid.md {
    padding: 60px 40px;
  }

  &_video {
    iframe {
      width: 100%;
      @include grid.md {
        min-width: 540px;
      }
      @include grid.lg {
        min-width: 720px;
      }
    }
    border: 0;
    outline: none;
    padding: 5px;
  }

  overflow: visible;
  .btn-wrap {
    position: absolute;
    bottom: -60px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .kw-button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &_close {
      height: 40px;
      width: unset;
      margin: 0;
      padding: 0 20px;
      border-radius: 10px;
      background-color: $input-bg-color;
      &:hover {
        background-color: $base-link-color;
      }
    }
  }
}
