@use "/src/styles/theme.scss";
@use "/src/styles/grid.scss";

.faq {
  font-family: theme.$font-mont;
  font-style: normal;
  font-weight: 500;

  @include grid.lg {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &-question {
    pointer-events: none;
    padding: 20px 36px;
    @include grid.lg-max {
      box-shadow: 0px 4px 52px rgba(7, 34, 76, 0.05);
      padding-right: calc(66px + 18px);
    }
  }

  &-list {
    &__item {
      border-right: 2px solid #e2e2e2;
      color: theme.$base-link-color;

      font-size: 18px;
      line-height: 23px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      min-height: 73px;
      padding: 0px;
      // padding: 20px 36px;
      cursor: pointer;

      transition: max-height 0.4s ease-out;

      position: relative;

      &:after {
        content: "";
        display: block;
        height: 18px;
        width: 18px;
        border: 2px solid #000000;
        border-top: 0;
        border-left: 0;
        transform: rotate(45grad);
        transition: all ease-out 0.15s;

        position: absolute;
        right: 33px;
        top: 24px;
        opacity: 1;
        @include grid.lg {
          display: none;
        }
      }

      &.active {
        height: auto;
        max-height: 100vh;
        transition: max-height 0.4s ease-in;

        border-color: theme.$acent-header-color;

    @include grid.lg-max {
        background: #eee;
    }

        box-shadow: 0px 4px 52px rgba(7, 34, 76, 0.05);
        cursor: default;

        &:after {
          transition: all ease-in 0.2s;
          // opacity: 0;
          transform: rotate(-150grad);
        }
      }
    }
  }
  &-answer {
    background: #fff;
    font-size: 14px;
    line-height: 29px;
    padding: 0px;
    padding-top: 8px;
  @include grid.md {
    padding-left: 35px;
  }


    color: #737373;
    display: none;
    @include grid.lg {
      display: block;
    }
  }

  &-mob-answer {
    @extend .faq-answer;
    overflow: hidden;
    font-weight: 400;
    display: block;

    font-size: 14px;
    line-height: 1.4;
    // width: calc(100% + 66px);
    width: 100%;

    @include grid.lg {
      display: none;
    }

    padding: 0 20px;
    max-height: 0;
    opacity: 0;
    transition: all 0.2s ease-out;
    .active > & {
    padding: 20px;
      opacity: 1;
      max-height: 100vh;
      transition: all 0.4s ease-in;
    }
  }
}
